<template>
  <div class="news">
    <div class="top_title">
      <div>
        <img src="../../assets/image/fastIcon.png"/>
        <span>快讯</span>
      </div>
      <div><span>{{ timer }}</span>秒后刷新</div>
    </div>
    <ul class="center_news">
      <li v-for="(item, index) in newsList.slice(0,10)" :key="index">
        <span>{{ item.showTime }}</span>
        <div @click="skipDetails(item.atUuid)" :class="{'title-hot': item.isSelected == 1}">{{ item.title }}</div>
      </li>
    </ul>
    <div class="more">
      <span @click="loadMore">
        <span>查看更多</span>
      <i class="fa fa-caret-right"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsFlash",
  data: () => ({
    newsList: [],
    pageInfo: {
      currentPage: 1,
      currentPageSize: 10
    },
    timer: 60,
  }),
  created() {
    this.getNews()
  },
  mounted() {
    /* 每秒定时刷新 */
    setInterval(() => {
      this.timer -= 1
      if (this.timer == 0) {
        this.getNews()
        this.timer = 60
      }
    }, 1000)

  },
  methods: {
    getNews() {
      this.axios.get('/index/fast').then(res => {
        this.newsList = res.page.list
      })
    },
    // 跳转详情
    skipDetails(atUuid) {
      let routeUrl = this.$router.resolve({path: '/fast-detail/' + atUuid});
      window.open(routeUrl.href, '_blank');
    },
    // 查看更多
    loadMore() {
      this.$router.push('/fast-list')
    }
  }
}
</script>

<style lang="scss" scoped>
// 快讯
.news {
  width: 100%;
  min-height: 600px;
  background: #FFFFFF;
  margin-top: 20px;
  border-radius: 4px;
  padding: 34px 20px 20px 50px;
  box-sizing: border-box;

  .top_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > :first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 600;
      position: relative;
    }

    > :nth-child(2) {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      line-height: 24px;
      color: #999999;
      letter-spacing: 1px;
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      position: absolute;
      left: -35px;
    }
  }

  .center_news {
    list-style: none;
    margin: 20px 0;
    padding: 0;

    li {
      margin-bottom: 20px;
      position: relative;


      &:not(:last-child):before {
        content: '';
        width: 3px;
        height: 120%;
        position: absolute;
        background: #E4E4E4;
        left: -22.5px;
        top: 18px;
      }

      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        color: #999999;
        line-height: 25px;
        letter-spacing: 1px;
        position: relative;


        &:before {
          content: '';
          width: 8px;
          height: 8px;
          background: #ffffff;
          border-radius: 50%;
          border: 3px solid #E4E4E4;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -28px;
        }
      }

      div {
        font-size: 14px;
        font-weight: bold;
        font-family: Source Han Sans CN;
        line-height: 25px;
        color: #333333;
        cursor: pointer;
      }

      div.title-hot {
        color: #d91e18;
      }
    }
  }

  .more {
    text-align: center;
    color: #3E35E7;
    font-size: 0.9rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 35px;
    cursor: pointer;
    position: relative;
    left: -10px;

    .fa {
      margin-left: 6px;
      font-size: 1.2rem;
      line-height: 35px;
      position: relative;
      top: 1.8px;
    }
  }
}
</style>
