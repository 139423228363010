<template>
  <div class="home_container">

    <div class="center" ref="center-dom">
      <!-- banner展示列表 -->
      <div class="swiper_container">
        <swiper v-if="topBanner.length > 0" :options="swiperBanner" ref="swiperBanner" class="swiperBanner">
          <swiper-slide v-for="(item, index) in topBanner" :key="index" @click.native="skipBanner(item.linkUrl)">
            <div class="swiperBanner_box">
              <div class="img_box">
                <img :src="item.imageUrl">
              </div>
              <div class="title">{{ item.bannerName }}</div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- 文章类表  -->
      <div>
        <article-list style="margin-top: 20px" :center-banner="centerBanner"></article-list>
      </div>
    </div>
    <div class="right" ref="asideHomeDom">
      <div>
        <div class="work_box">
          <h3>NFT作品展示</h3>
          <div>
            <swiper v-if="NFTWorks.length" :options="swiperShowCase" ref="swiperShowCase" class="swiperShowCase">
              <swiper-slide v-for="(works, index) in NFTWorks" :key="index" @click.native="skipWorks(works)">
                <div class="swiperShowCase_box">
                  <div class="img_box"><img :src="works.imageUrl"></div>
                  <ul>
                    <li><span>艺术品</span> {{ works.worksName }}</li>
                    <li><span>艺术家</span> {{ works.artist }}</li>
                    <li class="platform"><span>展示平台</span> {{ works.displayPlat }}</li>
                    <li><span>上链时间</span> {{ works.linkTime }}</li>
                    <div class="description">
                      <span class="name">作品简介</span>
                      <el-tooltip effect="light" :visible-arrow="false" placement="bottom"  :content="works.comments"
                                  popper-class="work-show-case">
                        <span>{{ works.comments }}</span>
                      </el-tooltip>

                    </div>
                  </ul>

                  <div class="more">
                  <span @click.stop="workOfMore">
                    <span>查看更多</span>
                    <i class="fa fa-caret-right"></i>
                  </span>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev swiper-button-prev-showcase" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-next-showcase" slot="button-next"></div>
            </swiper>
          </div>
        </div>
        <news-flash></news-flash>
        <hot-article ref="hotArticle" class="hot_article"></hot-article>
      </div>
    </div>

  </div>
</template>

<script>
import {swiper, swiperSlide} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import ArticleList from "./ArticleList/ArticleList";
//
import HotArticle from "../../components/HotArticle/HotArticle";
import NewsFlash from "../../components/NewsFlash/NewsFlash";

export default {
  name: "index",
  components: {
    NewsFlash,
    HotArticle,

    ArticleList,
    swiper, swiperSlide
  },
  data: () => ({
    // banner轮播配置
    swiperBanner: {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // // 设置点击箭头
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      },
      // 显示分页
      pagination: {
        el: ".swiper-pagination",
        clickable: true //允许分页点击跳转
      },
    },
    // 作品集轮播配置
    swiperShowCase: {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // // 设置点击箭头
      navigation: {
        prevEl: ".swiper-button-prev-showcase",
        nextEl: ".swiper-button-next-showcase"
      }
    },
    // 作品集
    NFTWorks: [],
    // 顶部Banner
    topBanner: [],
    centerBanner: []
  }),
  created() {
    this.$store.commit('setMenuActive', 0)
  },
  mounted() {
    this.init();
  },
  async beforeRouteEnter(to, from, next) {
    await next()
    // 热门文章跳转至 首页文章列表
    if (to.params.isScroll) {
      document.documentElement.scrollTop = 567
    }
  },
  methods: {

    init() {
      this.getNFTWorks()
      this.getBannerList()
    },

    // 获取作品集
    getNFTWorks() {
      this.axios.get('/index/nft').then(res => {
        this.NFTWorks = res.list
      })
    },
    // 获取更多作品
    workOfMore() {
      this.$router.push('/works-list')
    },
    // 获取banner列表
    getBannerList() {
      this.axios.get('/index/banner').then(res => {
        // 将中部和主banner拆分， 组件传递数据
        this.topBanner = res.topBanner
        this.centerBanner = res.centerBanner
      })
    },
    // banner轮播跳转
    skipBanner(linkUrl) {
      window.open(linkUrl)
    },
    // eslint-disable-next-line no-unused-vars
    skipWorks(works) {
      // window.open(works.linkUrl);
    }

  }
}
</script>

<style lang="scss" scoped>

.home_container {
  display: flex;
  position: relative;
  justify-content: space-between;

  .center {
    width: 787px;
    box-sizing: border-box;

    .swiper_container {
      background: #FFFFFF;
      box-sizing: border-box;
      padding: 27px 24px;
      border-radius: 4px;

      /deep/ .swiper-pagination {
        position: absolute;
        top: 385px;

        span {
          width: 10px;
          height: 10px;
          background: #FFFFFF;
        }
      }

      /deep/ .swiper-button-prev {
        width: 40px;
        height: 40px;
        background-image: url(../../assets/image/swiper_left.png);
        background-size: 40px 40px;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 150px;
      }

      /deep/ .swiper-button-next {
        width: 40px;
        height: 40px;
        background-image: url(../../assets/image/swiper_left.png);
        transform: rotate(180deg);
        background-size: 40px 40px;
        background-position: center;
        background-repeat: no-repeat;
        top: 150px;
        right: 0;
      }

      .swiperBanner {
        background: #FFFFFF;

        &:hover {
          /deep/ .swiper-button-prev {
            background-image: url(../../assets/image/swiper_left.png);
          }
        }

        .swiperBanner_box {
          position: relative;

          .img_box {
            height: 280px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .title {
            font-size: 1.4rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 51px;
            letter-spacing: 1px;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 54px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.4);
            color: #FFFFFF;
            padding-left: 10px;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
            text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
          }
        }
      }

    }
  }

  .right-ss {
    background: red;
    width: 100%;
    height: calc(100vh - 100px)
  }

  .right {
    //position: fixed;
    //margin-left: 787px;
    //height: calc(100vh - 100px);
    //min-height: calc(100vh - 100px - 263px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 283px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      margin-bottom: 40px;

      // 作品论播展示
      .work_box {
        width: 100%;
        background: #ffffff;
        border-radius: 4px;

        h3 {
          text-align: center;
          font-size: 1.4rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 60px;
          color: #333333;
        }

        /deep/ .swiperShowCase {
          .swiperShowCase_box {
            width: 230px;
            margin: 0 auto;
            padding-bottom: 20px;

            .img_box {
              width: 230px;
              height: 230px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            ul {
              width: 100%;
              list-style: none;
              padding: 0;
              margin: 20px 0 0;

              li {
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                color: #333333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                span {
                  font-size: 14px;
                  color: #999999;
                  width: 55px;
                  margin-right: 18px;
                  display: inline-block;
                }
              }

              .description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-top: 8px;
                line-height: 24px;
                overflow: hidden;
                position: relative;
                font-size: 14px;

                .name {
                  font-size: 14px;
                  color: #999999;
                  width: 56px;
                  margin-right: 18px;
                  margin-left: 2px;
                  display: inline-block;
                }

                color: #333333;
              }
            }

            .more {
              width: 100%;
              text-align: center;
              color: #3E35E7;
              font-size: 0.9rem;
              cursor: pointer;

              .fa {
                margin-left: 6px;
                font-size: 18px;
                line-height: 35px;
                position: relative;
                top: 1px;
              }
            }

          }

        }

        /deep/ .swiper-button-prev-showcase {
          width: 40px;
          height: 40px;
          background-image: url(../../assets/image/swiper_left.png);
          background-size: 40px 40px;
          background-position: center;
          background-repeat: no-repeat;
          left: 6%;
          top: 120px;
        }

        /deep/ .swiper-button-next-showcase {
          width: 40px;
          height: 40px;
          background-image: url(../../assets/image/swiper_left.png);
          transform: rotate(180deg);
          background-size: 40px 40px;
          background-position: center;
          background-repeat: no-repeat;
          top: 120px;
          right: 6%;
        }


      }

      .hot_article {
        margin-top: 40px;
      }
    }
  }


}
</style>
