<template>
  <div class="article_list_container">
    <ul class="tabs">
      <li
          @click="selectedTabs(item.atId, index)"
          ref="selectedTab"
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ active: item.isActive }"
      >
        {{ item.atName }}
      </li>
      <li class="tip_select" ref="tip_select"></li>
    </ul>
    <ul class="lists">
      <template v-if="articleList.length > 0">
        <li
            class="list_item"
            v-for="(item, index) in articleList.slice(0, 5)"
            :key="index"
        >
          <div class="cover">
            <img :src="item.imageUrl"/>
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item.atUuid)">
              {{ item.title }}
            </div>
            <p
                v-html="item.introduction"
                class="content"
                @click="skipDetails(item.atUuid)"
            ></p>
            <div class="tools">
              <div class="author" @click="skipAuthor(item)">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="../../../assets/image/authorImg.png"/>
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags">
                <li
                    v-for="lable in item.labelList.slice(0, 3)"
                    :key="lable.ltId"
                    @click="shipTagList(lable.ltName)" 
                >
                   {{ lable.ltName }}
                </li>
              </ul>
              <span class="showTime">{{ item.showTime }}</span>
              <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg"
                    @mouseover="intoImg(item)">
                <i class="fa fa-share-alt"></i>
                <div class="share_img">
                  <div :ref="'qr'+item.atUuid"></div>
                </div>
              </span>
            </div>
          </div>
        </li>
        <template v-if="centerBanner.length > 0 && articleList.length >= 5">
          <li class="list_banner">
            <img
                :src="centerBanner[0].imageUrl"
                @click="skipBanner(centerBanner[0].linkUrl)"
            />
          </li>
        </template>
      </template>
      <template v-if="articleList.length > 5">
        <li
            class="list_item"
            v-for="item in articleList.slice(5, 10)"
            :key="item.atUuid"
        >
          <div class="cover">
            <img :src="item.imageUrl"/>
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item.atUuid)">
              {{ item.title }}
            </div>
            <p
                v-html="item.introduction"
                class="content"
                @click="skipDetails(item.atUuid)"
            ></p>
            <div class="tools">
              <div class="author">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="../../../assets/image/authorImg.png"/>
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags">
                <li
                    v-for="lable in item.labelList.slice(0, 3)"
                    :key="lable.ltId"
                    @click="shipTagList(lable.ltName)"
                >
                    {{ lable.ltName }}
                </li>
              </ul>
              <span class="showTime">{{ item.showTime }}</span>
              <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg"
                    @mouseover="intoImg(item)">
                <i class="fa fa-share-alt"></i>
                <div class="share_img">
                  <div :ref="'qr'+item.atUuid"></div>
                </div>
              </span>
            </div>
          </div>
        </li>
        <template v-if="centerBanner.length > 1 && articleList.length >= 10">
          <li class="list_banner">
            <img
                :src="centerBanner[1].imageUrl"
                @click="skipBanner(centerBanner[1].linkUrl)"
            />
          </li>
         
        </template>
      </template>

      <template v-if="articleList.length > 10">
        <li
            class="list_item"
            v-for="item in articleList.slice(10, 15)"
            :key="item.atUuid"
        >
          <div class="cover">
            <img :src="item.imageUrl"/>
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item.atUuid)">
              {{ item.title }}
            </div>
            <p
                v-html="item.introduction"
                class="content"
                @click="skipDetails(item.atUuid)"
            ></p>
            <div class="tools">
              <div class="author">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="../../../assets/image/authorImg.png"/>
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags">
                <li
                    v-for="lable in item.labelList.slice(0, 3)"
                    :key="lable.ltId"
                    @click="shipTagList(lable.ltName)"
                >
                {{ lable.ltName }}
                </li>
              </ul>
              <span class="showTime">{{ item.showTime }}</span>
              <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg"
                    @mouseover="intoImg(item)">
                <i class="fa fa-share-alt"></i>
                <div class="share_img">
                  <div :ref="'qr'+item.atUuid"></div>
                </div>
              </span>
            </div>
          </div>
        </li>
        <template v-if="centerBanner.length > 2 && articleList.length >= 15">
          <li class="list_banner">
            <img
                :src="centerBanner[2].imageUrl"
                @click="skipBanner(centerBanner[2].linkUrl)"
            />
          </li>
        </template>
      </template>

      <template v-if="articleList.length > 15">
        <li
            class="list_item"
            v-for="item in articleList.slice(15)"
            :key="item.atUuid"
        >
          <div class="cover">
            <img :src="item.imageUrl"/>
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item.atUuid)">
              {{ item.title }}
            </div>
            <p
                v-html="item.introduction"
                class="content"
                @click="skipDetails(item.atUuid)"
            ></p>
            <div class="tools">
              <div class="author">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="../../../assets/image/authorImg.png"/>
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags">
                <li
                    v-for="lable in item.labelList.slice(0, 3)"
                    :key="lable.ltId"
                    @click="shipTagList(lable.ltName)"
                >
                  {{ lable.ltName }}
                </li>
              </ul>
              <span class="showTime">{{ item.showTime }}</span>
              <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg"
                    @mouseover="intoImg(item)">
                <i class="fa fa-share-alt"></i>
                <div class="share_img">
                  <div :ref="'qr'+item.atUuid"></div>
                </div>
              </span>
            </div>
          </div>
        </li>
      </template>
      <div @click="dianji" class="moreBox" v-if="currentPage<totalPage">
        查看更多
      </div>
    </ul>
  </div>
</template>

<script>
let timer;
import QRCode from 'qrcodejs2'

export default {
  name: "ArticleList",
  props: {
    centerBanner: {
      Type: Array,
      default: [],
    },
  },
  data: () => ({
    curType: 0,
    tabs: [],
    // 文章集合
    articleList: [],
    bannerList: [],

    currentPage: 1,
    currentPageSize: 15,
    totalPage: 100,
    type: "",
    codeBaseUrl: "https://www.metaversehub.net/nft-api/h5/article/",
    imgTimer: null,
    tagContentClss:'tagContent'
  }),
  created() {
    this.init();
  },
  mounted() {
    // window.onscroll = () => {
    //   var scrollTop =
    //       document.documentElement.scrollTop || document.body.scrollTop;
    //   var windowHeight =
    //       document.documentElement.clientHeight || document.body.clientHeight;
    //   var scrollHeight =
    //       document.documentElement.scrollHeight || document.body.scrollHeight;
    //   if (scrollTop + windowHeight >= scrollHeight - 1000) {
    //     let params = {
    //       currentPage: this.currentPage + 1,
    //       currentPageSize: 5,
    //       type: this.type,
    //     };
    //     this.throttle(params);
    //   }
    // };
   // this.dianji();
  },
  methods: {
    dianji(){
      let params = {
          currentPage: this.currentPage + 1,
          currentPageSize: 15,
          type: this.type,
        };
        this.throttle(params);
    },
    intoImg(obj) {
      this.imgTimer = setTimeout(() => {
        this.addTime(obj);
      }, 200)
    },
    leaveImg() {
      clearInterval(this.imgTimer)
    },
    addTime(obj) {
      this.axios.post('/article/share', {uuid: obj.atUuid}).then(res => {
        console.log('res', res)
      })
    },
    async init() {
      await this.getArticleType();
      let params = {
        currentPage: 1,
        currentPageSize: 15,
        type: this.type,
      };

      await this.getArticleList(params);
      this.articleList.forEach(item => {
        this.createImg(item);
      });
    },
    // 切换文章类型
    async selectedTabs(atId, index) {
      this.type = atId;
      // 解决分页插件 保证分页总条数 > 1
      this.totalPage = 100;
      let that = this;
      this.tabs.forEach((item, subIndex) => {
        subIndex == index ? (item.isActive = true) : (item.isActive = false);
        that.$refs.tip_select.style.left = 100 + index * 120 + "px";
      });
      let params = {currentPage: 1, currentPageSize: 15, type: atId};
      this.articleList = [];
      await this.getArticleList(params);
      this.articleList.forEach(item => {
        this.createImg(item);
      });
    },
    // 获取文章集合
    getArticleList(params) {
      if (this.totalPage <= this.currentPage) return;
      return this.axios.get("/index/article", {params}).then((res) => {
        console.log('结果',res);
        let tempList = this.articleList.slice();
        this.articleList = tempList.concat(res.page.list);
        this.currentPage = res.page.currPage;
        this.totalPage = res.page.totalPage;

        // this.articleList.forEach(item => {
        //   this.createImg(item);
        // });
      });
    },
    createImg(obj) {
      let _this = this;
      console.log(obj)
      new QRCode(this.$refs['qr' + obj.atUuid][0], {
        text: _this.codeBaseUrl + obj.atUuid, // 需要转换为二维码的内容
        width: 110,
        height: 110,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    throttle(params) {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout( async() => {
        await this.getArticleList(params);
        timer = undefined;
        this.articleList.forEach(item => {
          this.createImg(item);
        });

      }, 100);
    },
    // 获取文章类型
    getArticleType() {
      return this.axios.get("/index/article-type").then((res) => {
        let that = this;
        let resultList = that.tabs.concat(res.list);
        if (resultList.length > 0) {
          resultList[0].isActive = true;
          this.type = resultList[0].atId;
          resultList.push({atId: "", atName: "全部", isActive: false});
        } else {
          resultList.push({atId: "", atName: "全部", isActive: true});
        }
        this.tabs = resultList;
      });
    },
    // 跳转详情
    skipDetails(atUuid) {
      // this.$router.push('/article-detail/' + atUuid)
      let routeUrl = this.$router.resolve({
        path: "/article-detail/" + atUuid,
      });
      window.open(routeUrl.href, "_blank");
    },
    // 跳转作者
    skipAuthor(item) {
      this.$router.push("/author-article-list/" + item.auId);
    },
    skipBanner(url) {
      window.open(url);
    },
    shipTagList(name) {
      this.$router.push({name: "TagList", params: {tagType: name}});
    },
  },
};
</script>

<style lang="scss" scoped>
.article_list_container {
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 30px;
  margin-bottom: 40px;

  .tabs {
    height: 60px;
    list-style: none;
    display: flex;
    position: relative;
    margin: 0 0 0 0;
    border-bottom: 1px solid #dddddd;
    padding-left: 60px;

    li {
      font-size: 1.2rem;
      font-family: "PingFangSC-Semibold", "Helvetica Neue Bold",
      "Microsoft YaHei Bold", sans-serif;
      font-weight: bold;
      letter-spacing: 1px;
      color: #000000;
      line-height: 60px;
      cursor: pointer;
      margin: 0 15px;
      text-align: center;
      user-select: none;
      position: relative;

      &:hover {
        color: #3e35e7;

        &:after {
          width: 100%;
        }
      }

      &:after {
        content: "";
        width: 0;
        position: absolute;
        bottom: -2px;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background: #3e35e7;
        border-radius: 8px;
        transition: all 0.3s ease-in;
        z-index: 3;
      }
    }

    .active:after {
      width: 100%;
    }

    
  }

  .lists {
    .list_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      margin: 0 15px;
      border-bottom: 1px solid #efefef;
      cursor: pointer;

      .cover {
        width: 140px;
        height: 100px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      .content_container {
        flex: 1;
        width: 0;
        position: relative;
        top: -5px;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 20px;
          font-family: "PingFangSC-Semibold", "Microsoft YaHei Bold", sans-serif;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 41px;
          color: #000000;

          &:hover {
            color: #3e35e7;
          }
        }

        .content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
          word-break: break-word;
          font-family: pingfang SC,helvetica neue,arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun,sans-serif;
          line-height: 30px;
          color: #333333;
          letter-spacing: 1px;
          margin: 0;
        }

        .tools {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;

          .author {
            display: flex;
            align-items: center;

            img {
              height: 24px;
              width: 24px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 5px;
            }

            .nick_name {
              // width: 70px;
              font-family: "PingFangSC-Semibold", "Microsoft YaHei Bold",
              sans-serif;
              font-weight: bold;
              font-size: 12px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .tags {
            flex: 1;
            display: flex;
            margin-left:20px;

            li {
              height: 20px;
              line-height: 20px;
              box-sizing: border-box;
              text-align: center;
              font-family: "PingFangSC-Light", "Helvetica Neue Light",
              "Microsoft YaHei Light", sans-serif;
              letter-spacing: 1px;
              background: #ececec;
              font-size: 12px;
              color: #3e35e7;
              border-radius: 8px;
              padding: 0 8px;
             // white-space:nowrap;//不折行

              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }
          // .tagContent{
          //   height: 50px !important;
          // }
          .share {
            position: relative;

            &:hover .share_img {
              visibility: visible;
              transition: visibility 1s ease;
            }

            .share_img {
              visibility: hidden;
              cursor: pointer;
              box-sizing: border-box;
              padding: 6px;
              position: absolute;
              left: -130px;
              top: -60px;
              background-color: #FFFFFF;
              margin: 0;
              width: 120px;
              height: 120px;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
              overflow: hidden
            }
          }

          @media (min-width: 1280px) {
            .tags {
              li {
                &:not(:last-child) {
                  margin-right: 20px;
                }
              }
            }
          }

          .showTime {
            font-size: 14px;
            color: #999999;
            letter-spacing: 1px;
          }
        }
      }
    }

    .list_banner {
      margin: 0 15px;
      padding: 30px 0;
      border-bottom: 1px solid #efefef;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}
.moreBox{
        margin: auto;
        border: 1px solid #3e35e7;
        border-radius: 4px;
        width: 230px;
        padding: 12px 0;
        text-align: center;
        color: #3e35e7;
        margin-top: 32px;
        font-family: PingFangSC-Semibold, sans-serif;
        letter-spacing:1px;
    }
    .moreBox:hover{
      background-color: #e1e0f5;
      cursor: pointer;
    }
</style>
